.review-card-col {
	width: calc(100% * 0.2);
}
.review-card-height {
	height: calc(100% - 1.75rem);
}

.review-big-card-green {
	background-color: #fff;
	border-left: 10px solid var(--primary-bg-color);
}

.review-big-card-red {
	background-color: #fff;
	/* border-left: 10px solid var(--danger-bg-color); */
	border-left: 10px solid red;
}

.outer {
	position: relative;
	width: 250px;
	height: 150px;
}
.canvas {
	position: absolute;
}
.percent {
	position: absolute;
	left: 50%;
	transform: translate(-50%, 0);
	font-size: 50px;
	bottom: 0;
}
