
.eyes-content{
    display: flex;
    justify-content: start;
    align-items: center;
    margin-bottom: 15px;
    position: relative;
}

.eyes-content svg{
    position: absolute;
    right: 0;
}

.eyes-content span {
    margin-left: 10px;
}