.popup-overlay {
    background-color: rgb(0 0 0 / 50%);
}
/*
.modal {
    font-size: 14px;
    background-color: #fff;
    border-radius: 5px;
    padding: 1rem 2rem 1rem 2rem;
}
.modal > .header {
    width: 100%;
    border-bottom: 1px solid gray;
    font-size: 18px;
    text-align: center;
    padding: 5px;
}
.modal .content div {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    cursor: pointer !important;
}*/
.eyes-content svg{
    float: right;
    margin-top: 5px;
}

.btn-group{
    display: flex;
}

.btn-group button {
    cursor: pointer; /* Pointer/hand icon */
    float: left; /* Float the buttons side by side */
  }
  
  /* Clear floats (clearfix hack) */
  .btn-group:after {
    content: "";
    clear: both;
    display: table;
  }
  
  .btn-group button:not(:last-child) {
    border-right: none; /* Prevent double borders */
  }
  
  .btn-group button:first-child {
    border-radius: 10px 0 0 10px/* Prevent double borders */
  }
  .btn-group button:last-child {
    border-radius: 0px 10px 10px 0; /* Prevent double borders */
  }

  .btnSuccess{
    background-color: #198754;
    border-color: #198754;
  }

  .btnInfo{
    background-color: #0dcaf0;
    border-color: #0dcaf0;
  }

  .modal-xl{
    max-width: 1500px !important;
  }