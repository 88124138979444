.card-icon {
	font-size: 4em;
	align-items: center !important;
}

.card-text {
	color: #fff;
	padding: 0.5rem;
	text-align: center;
	border-radius: 5px;
	font-size: 15px;
}
.form-control,
.btnExport,
.css-b62m3t-container {
	margin: 5px 0;
	min-height: 40px;
}

.row-container > * {
	width: calc(100% / 3);
}

.card-badge {
	cursor: pointer;
}
