body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
		'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
		'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.main-loading {
	position: absolute !important;
	top: 50%;
	left: 48%;
}

input[type='checkbox'] {
	height: 0;
	width: 0;
	visibility: hidden;
}

label.switchLabel {
	cursor: pointer;
	text-indent: -9999px;
	width: 50px;
	height: 25px;
	background: grey;
	display: block;
	border-radius: 100px;
	position: relative;
	margin: 0;
}

label.switchLabel:after {
	content: '';
	position: absolute;
	top: 5px;
	left: 5px;
	width: 15px;
	height: 15px;
	background: #fff;
	border-radius: 90px;
	transition: 0.25s;
}

input:checked + label.switchLabel {
	background: #38cab3;
}

input:checked + label.switchLabel:after {
	left: calc(100% - 5px);
	transform: translateX(-100%);
}

label.switchLabel:active:after {
	width: 10px;
}

/* Modo oscuro para los react select */
.dark-theme .react-select__control,
.dark-theme .react-select__menu {
	color: #ffffe3;
	background-color: #2a2e3f;
	border: 1px solid #444566;
}

.dark-theme .react-select__multi-value > * {
	color: #bfc1d4;
	background-color: #383d52;
	border: 1px solid #444566;
}

.dark-theme .react-select__option {
	background-color: inherit !important;
}

.dark-theme .react-select__option:hover {
	background-color: inherit !important;
	color: var(--primary-bg-color) !important;
}

.dark-theme .react-select__single-value {
	color: #bfc1d4;
}
/* Modo oscuro para los react select */
