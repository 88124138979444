#form {
	width: 250px;
	margin: 0 auto;
	height: 50px;
}
#form label {
	font-size: 20px;
}

input[type='radio'] {
	display: none;
}

label {
	color: grey;
}

.clasificacion {
	direction: rtl;
	unicode-bidi: bidi-override;
	text-align: left;
}

/* label:hover,
  label:hover ~ label {
    color: orange;
  }
  
  input[type="radio"]:checked ~ label {
    color: orange;
  } */

.clasificacion > label {
	font-size: 2rem;
}
