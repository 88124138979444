hr {
	margin-top: 1rem;
	margin-bottom: 1rem;
	border: 0;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.zoom {
	cursor: -webkit-zoom-in;
	cursor: zoom-in;
}

.acc div a {
	padding: 12px;
	border-radius: 5px;
}
