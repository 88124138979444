.tableContainer{
    height: 55vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

Table thead{
    position: sticky;
    top: 0;
    margin: 0;
}

.arrowPagination {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: solid 1px gray;
}

.paginationArrows{
    float: right;
    margin-top: 10px;
    display: flex;
    justify-content: space-around;
    width: 160px;
}

button.arrowPagination:disabled {
    background: rgb(211, 211, 211);
    color:black;
    border:solid 1px rgb(211, 211, 211);
    cursor: not-allowed;
}
button.arrowPagination {
    background: #0d6efd;
    color:white;
    border:solid 1px #0d6efd;
    cursor:pointer
}

.paginationSelect{
    padding: 10px;
    border: solid 1px #ccc;
    border-radius: 10px;
    background: transparent;
}
.paginationDiv{
    margin-bottom: 10px
}
.showPagination{
    border: solid 1px #ccc;
    padding: 10px;
    border-radius: 10px;
}
.resultPagination{
    margin-top: 10px;
    float: left;
}
th{
    white-space: nowrap;
}