.text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
}

.text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
}

.font-bold {
    font-weight: 700;
}

.text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
}

.gap-2 {
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

.ml-5 {
    margin-left: 1.25rem;
}
.list-disc {
    list-style-type: disc;
}
.background{
    height: 100vh;
    background-image: url('../../assets/img/notifications/background.png');
    background-size: 100% 50%;
    background-position: bottom;
    background-repeat: no-repeat;
}
.col-per{
    height: 85vh;
}
.list > p {
    margin: 0;
    color: #122a44;
    font-size: 21px;
}
.list {
    margin-bottom: 20px;
}
.list > span {
    color: #122a44;
    font-size: 15px;
}
.ulList{
    list-style-image: url('../../assets/img/notifications/on.png');
}
.list + .disabled {
    filter: grayscale(100%);
}
.list + .disabled > p {
    color: #9f9f9f;
}
.phone{
    width: 80%;
    height: 95%;
}
.title{
    font-size: 80px;
    margin: 0;
}
.subtitle{
    font-size: 35px;
    text-align: right;
}
article{
    font-size: 20px;
    text-align: justify;
}
.icons {
    margin-top: 25px;
  }

@media only screen and (max-width: 1600px) {
    .list {
        margin-bottom: 2%;
    }
    .title{
        font-size: 75px;
    }
    .icons {
        margin-top: 15px;
    }
  }