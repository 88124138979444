  
.dark-theme .calendar-table {
    background-color: #383d52 !important;
    border: 1px solid #444566 !important;
}

.dark-theme .daterangepicker {
    background-color: #2a2e3f !important;
    border: 1px solid #444566 !important;
}

.dark-theme .in-range {
    background-color: #2a2e3f !important;
    color: white !important;
}

.dark-theme .off {
    background-color: #383d52 !important;
}

.dark-theme .daterangepicker .ranges li:hover {
    background-color: transparent !important;
    color: var(--primary-bg-color);
}

.dark-theme .daterangepicker td.available:hover, .daterangepicker th.available:hover {
    background-color: transparent !important;
    color: var(--primary-bg-color);
}