.main-signup-header h2{color: #3b8ccb !important}
.btn-primaryLogin{
  color:#fff !important;
  background-color: #3b8ccb !important;
  border-color: #3b8ccb !important;
}
.page-login{
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  background: url(../../assets/img/backgrounds/left.png) 0 0 no-repeat;
  background-color: #ed2024 !important;
  background-size: contain;
  position: relative;
}
.logo-delpa-login{
  right: 40px;
  width: 30%;
}
@media only screen and (max-width: 1024px) {
  .logo-delpa-login{
    display: none;
  }
}
@media only screen and (max-width: 425px) {
  .page-login{
    background: unset;
  }
}